import axios from "./axios";
import { api } from "./axios.config";

const AUTH_API = api.authApiUrl;
const API_URL = api.apiUrl;
const API_MANAGE_URL = api.manageApiUrl;

const USERS = "/user";
const USERS_AUTH = "/auth/user";

const VALIDATE = "/v2/validate";

export function getUserById() {
	return axios.get(API_URL + USERS + "/");
}

export function createUser(user) {
	return axios.post(AUTH_API + USERS_AUTH, user);
}

export function updateUser(data, token) {
	return axios.put(API_URL + USERS + "/", data, { headers: { access_token: token } });
}

export function validateUserPhone(phone_number, dial_code) {
	return axios.get(API_URL + VALIDATE, { params: { phone_number, dial_code } });
}

export const getRegistrations = () => {
	return axios.get(AUTH_API + USERS + "/registrations");
};

export const getRegistrationDetailsApi = booking_id => {
	return axios.get(AUTH_API + USERS + "/registrations/" + booking_id);
};

export const subscribeUser = (emailId, clientType, subscriptionSource) => {
	const data = { email_id: emailId };
	return axios.post(AUTH_API + "/subscribe", data, {
		headers: { "client-type": clientType, "subscription-source": subscriptionSource }
	});
};

export const deleteAccount = (data, token) => {
	return axios.delete(API_MANAGE_URL + USERS, data, { headers: { access_token: token } });
};
